/* src/WeddingLandingPage.css */

body {
  font-family: Arial, sans-serif;
  text-align: center;
  background-color: #f0f8ff;
  margin: 0;
  padding: 0;
}

.WeddingLandingPage header {
  background-color: #ffffff;
  padding: 50px 0;
}

.WeddingLandingPage h1 {
  font-size: 2.5em;
  margin: 0;
  color: #333;
}

.WeddingLandingPage .container {
  margin: 30px auto;
  width: 80%;
}

.WeddingLandingPage p {
  font-size: 1.2em;
  color: #666;
}

.button {
  display: inline-block;
  margin: 15px;
  padding: 15px 30px;
  font-size: 1.2em;
  color: #fff;
  background-color: #28a745;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #1e7e34;
}

@media (max-width: 600px) {
  .button {
    width: 80%;
    margin: 10px 0;
  }
}